import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './/NavBar'
import Footer from './atoms/Footer'

export const MainLayout = () => {
  return (
    <>
    <Navbar />
    <Outlet />
    <Footer />
    </>
  )
}