import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import sanityClient from "../client";
// import { FaArrowLeft, FaMapMarker } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Container from "./atoms/Container";
import styled from "styled-components";
import TournamentItem from "./TournamentItem";
import {CSVLink} from 'react-csv'
import axios from 'axios'


const Title = styled.h3`
  color: rgba(31, 41, 55, 1);
 
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  text-align: center;
`;

const StyleList = styled.div`
  padding: 1.5rem;
  background-color: white;
  position: relative;
  
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    
`;


const TourContainer = styled(Container)`
  max-width: 670px;
  margin: auto;
  @media only screen and (max-width: 40em) {
    width: 90%;
    margin-top: 2rem;
  }
`;

const StyleTable = styled.table`
    max-width: 670px;
    margin: auto;

    border: 1px solid;
    padding: 15px;
    text-align: center;
    margin-top: 40px;
    width: 90%
`;

const StyleRowFirst = styled.tr`
    white-space: nowrap;
    border: 2px solid;
    border-color: black black grey black;
`

const StyleRowSecond = styled.tr`
  border: 2px solid;
  background-color: lightblue;
`

const renderHeader = () => {
    return (
    <thead>
    <StyleRowSecond key='header'>
      <th>L.p</th><th>Imię i Nazwisko</th><th>WK</th><th>PID</th>
    </StyleRowSecond>
    </thead>)
  }

const renderTeamHeader = () => {
return (<thead>
      <StyleRowSecond key='header'>
          <th>L.p</th><th>Nazwa teamu</th><th>Imię i Nazwisko</th><th>WK</th><th>PID</th>
      </StyleRowSecond>
      </thead>)
}

const span = 2


const  RegistrationPage = () => {
    const [registrationPage, setRegistrationPage] = useState(null);
    const { slug } = useParams();
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                title, 
                date,
                date_registration,
                slug,
                typeName,
                description,
                regFee,
                Fee,
                redFee,
            }`
          )
          .then((data) => setRegistrationPage(data[0]))
          .catch(console.error);
        
        
        

      },[slug]);
      
      useEffect(() => {
        if (registrationPage) {
          if (registrationPage.typeName === 'couples') {
    
            const fetchPairs = async () => {
              const apiUrl = `https://brydzwroclaw.com/v1/pairs/${slug}`
              try {
                const res = await axios.get(apiUrl, slug)
                console.log(res.data)
                setPlayers(res.data)
                
              } catch(error){
                console.log('Error fetching data', error)
              }
            }
            
            fetchPairs()
          };

          if (registrationPage.typeName === 'teams') {
              const fetchTeams = async () => {
                // const apiUrl = `http://localhost:8080/teams?tournament=${slug}`
                const apiUrl = `https://brydzwroclaw.com/v1/teams/${slug}`
                console.log(apiUrl)
                try {
                  const res = await axios.get(apiUrl)
                  
                  setPlayers(res.data)
                
                } catch(error){
                  console.log('Error fetching data', error)
                }
              }
              
              fetchTeams()
          }
        };
        },[registrationPage]);

      if (!registrationPage) return <div>Ładowanie...</div>;
      
      const csvData = () => { 
          if (registrationPage.typeName === 'teams') {
            return players.map(item=>(
              {
                team: item.team,
                tournament: item.tournament,
                name: item.player_name,
                WK: parseFloat(item.WK),
                PID: item.PID
              })
            )
            }
            else {
              console.log(players)
              return players.map((item, index)=>({
                  tournament: item.tournament,
                  name: item.player,
                  WK: item.WK,
                  PID: item.PID
              }
              ))
              }
        }
      // const csvData = () => { 
      //     if (registrationPage.typeName === 'teams') {
      //       console.log(players)
      //       return players.map(item=>(
      //         console.log(item)
      //       ))
      //     }
      //   };
  
        const flat =() => {
            const flaten = {}
            csvData.map((item, index) => {
                let size = item.length
                item.map((inneritem, innerindex) =>{
                    flaten[size*index+innerindex] = inneritem 
                } ) 
            })
            return Object.values(flaten) 
        };
      
        const finalData = () => {  
          if(registrationPage.type === 'teams'){
            return flat() }
          else{
            return csvData()}
        }
      
    const csvDataOst = finalData()

    const fileHeaders = (type) => {
      if (type === 'teams')
        return [
        {label: "Team", key: "team"},
        {label: "Turniej", key: "tournament"},
        {label: "Gracz", key: "name"},
        {label: "WK", key: "WK"},
        {label: "PID", key: "PID"},
        ]
      else
        return [
          {label: "Turniej", key: "tournament"},
          {label: "Gracz1", key: "name1"},
          {label: "WK1", key: "WK1"},
          {label: "PID1", key: "PID1"},
          {label: "Gracz2", key: "name2"},
          {label: "WK2", key: "WK2"},
          {label: "PID2", key: "PID2"},]
      }

    const CreateCSV = () =>{
        return (
            <div>
              {csvDataOst?.length &&
                <CSVLink
                  className="bg-indigo-500 hover:bg-indigo-600 text-white text-center font-bold py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline mt-4 block"
                  headers={fileHeaders(registrationPage.typeName)}
                  data={csvDataOst}
                  filename={`registered_${slug}.csv`}
                  target="_blank"
                >
                  Exportuj do CSV
                </CSVLink>
              }
            </div>
          );
    }

    const renderPlayers = () => {
        const id = (index) => (index+1)
        return (<tbody>
        {players.map((data, index) => { 
          return (
            <>
            {index%2 === 0 ?  <StyleRowFirst key = {index}> 
            <td rowSpan={span}>{id(index)}</td>
            <td>{data.player}</td>
            <td>{data.WK}</td>
            <td>
            <Link
            to= {`//msc.com.pl/cezar/?p=21&pid_search=${data.PID}`}>{data.PID}</Link></td>
            </StyleRowFirst> : 
            
            <StyleRowSecond key = {1000+index}>
            <td>{data.player}</td>
            <td>{data.WK}</td>
            <td><Link
            to= {`//msc.com.pl/cezar/?p=21&pid_search=${data.PID}`}>{data.PID}</Link></td>
            </StyleRowSecond>
            }
            </>
            )})
          } </tbody>)
        }
   
      const renderTeams = () => {
        const counter  = (index, count) => (index+count)
        const render_one_player = (team,inner_index, index, team_index) => {
          if (inner_index === 0){
            console.log(parseInt(team.player_count))
            return (
              <StyleRowFirst key={index}>
              <td rowSpan={parseInt(team.player_count)}>{team_index}</td>
              <td rowSpan={parseInt(team.player_count)}>{team.team}</td>
              <td>{team.player_name}</td>
              <td>{team.WK}</td>
              <td><Link
              to= {`//msc.com.pl/cezar/?p=21&pid_search=${team.PID}`}>{team.PID}</Link></td></StyleRowFirst>
              )
              }
          if (inner_index % 2 === 1) {
            return(
              <StyleRowSecond key ={index}>
              <td>{team.player_name}</td>
              <td>{team.WK}</td>
              <td><Link
              to= {`//msc.com.pl/cezar/?p=21&pid_search=${team.PID}`}>{team.PID}</Link></td></StyleRowSecond> 
            )
          }
          return (
          <StyleRowFirst key = {index}><td>{team.player_name}</td>
              <td>{team.WK}</td>
              <td><Link
              to= {`//msc.com.pl/cezar/?p=21&pid_search=${team.PID}`}>{team.PID}</Link></td></StyleRowFirst>
          )
        }
        
        let count = 0
        let index_count = 0

        return (
        <tbody>
        {players.map((team, index) => {
            console.log(team)
            if ( index - count === 0 ) {
              count = counter(index, team.player_count)
              index_count = index_count + 1
            }
            return(
              <>
              {render_one_player(team,index+team.player_count - count, index, index_count )}
              </>
            )
            })}
        </tbody>
        )
      }

    return (
        <>
        <section>
            <div className="container m-auto py-6 px-6">
                <Link
                to="/zapisy"
                className="text-indigo-500 hover:text-indigo-600 flex items-center"
                >
                <button className='mr-2'> Wroc do zapisów </button> 
                </Link>
            </div>
            </section>

            <TourContainer>
            <TournamentItem item = {registrationPage} slug = {slug} />



            <StyleList key = 'zapisani'>
                <Title>Obecnie zapisani</Title>

                <StyleTable>
                    {registrationPage.typeName === "couples" ? renderHeader() : renderTeamHeader() }
                    {registrationPage.typeName === "couples" ? renderPlayers() : renderTeams() }

                </StyleTable>
            </StyleList>

        {CreateCSV(slug)}       
        </TourContainer>
        
        </>
  )
}

// const playerLoader = async ({param}) => {
//     // const res = await fetch(`http://localhost:8000/tournaments/?_tournament=${param.tournament}`)
//     const res = await fetch(`http://localhost:8000/tournaments/${param.id}`)
//     const data = await res.json()
//     return data
// } 

export {RegistrationPage as default}