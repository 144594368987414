import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
 


const RegistrationFormTeams = ({addTeamSubmit}) => {
  const [team, setTeam] = useState("")
  const [players_count, setPlayersCount] = useState("")
  const [players, setPlayers] =  useState([])
  const [showForm, setShowForm] = useState(false)

  const navigate = useNavigate()
  const {slug} = useParams()


  function handleUpdateName  (idx, value) {
    const updatePlayer = players.map((player, index) =>{
        if (index === idx ){
          return {...player, name: value}
        }
        return player
        })

    setPlayers(updatePlayer)
    }

  function handleUpdateWK (idx, value) {
    const updatePlayer = players.map((player) =>{
      if (player.id === idx) {
        return {...player,
        WK: value}
      } else
        return player
    })
    setPlayers(updatePlayer)
  }

  function handleUpdatePID  (idx, value) {
    const updatePlayer = players.map((player) =>{
      if (player.id === idx) {
        return {...player,
        PID: value}
      } else
        return player
    })
    setPlayers(updatePlayer)
  }

  const newPlayerList = (count) => {
    return(
    Array.from({ length: count }, (_, i) => i+1).map((_, index)=>{
      return({id: index, name: "", WK:"", PID: ""})
      })
    )
   }

  const showInputs = () => {
    return(
        <div>
        {players.map((player, index) => {
          return(
            <section key={`Zawodnik ${index+1}`}>
              <h1 className="block text-gray-900 font-bold text-lg mb-2">{`Zawodnik ${index+1}`}</h1>
            <div className="mb-4" >
            <label className="block text-gray-700 font-bold mb-2"
            >Imię i nazwisko</label>
            <input
            type="text"
            id="name"
            name="name"
            className="border rounded w-full py-2 px-3 mb-2"
            required
            value = {player.name}
            onChange={(e) => handleUpdateName(index, e.target.value)}
            />
            </div>
            <div className="mb-4" >
            <label className="block text-gray-700 font-bold mb-2"
            >PID</label>
            <input
            type="number"
            id="PID"
            name="PID"
            className="border rounded w-full py-2 px-3 mb-2"
            required
            min = "0"
            max = "100000"
            value = {player.PID}
            onChange={(e) => handleUpdatePID(index, e.target.value)}
            />
            </div>
            <div className="mb-4" >
            <label className="block text-gray-700 font-bold mb-2"
            >WK</label>
            <select
            type="text"
            id="WK"
            name="WK"
            className="border rounded w-full py-2 px-3 mb-2"
            required
            value = {player.WK}
            onChange={(e) => handleUpdateWK(index, e.target.value)}>
              <option value=""></option>
              <option value="0.0">0.0</option>
              <option value="0.5">0.5</option>
              <option value="1.0">1.0</option>
              <option value="1.5">1.5</option>
              <option value="2.0">2.0</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="7">7</option>
              <option value="9">9</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="15">15</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="21">21</option>
              <option value="24">24</option>
            </select>
            </div>
            </section>
            )})
          }    
        </div>
        
        )
        
  }   
  

  const submitForm = (e) => {
    
    e.preventDefault()

      players.map((item) => {
         const newTeam ={
          tournament: slug,
          team: team,
          player_count: players_count,
          player_name: item.name,
          WK: item.WK,
          PID: item.PID
         } 
        console.log(newTeam)
        addTeamSubmit(newTeam, slug, players_count)
      })
        

      return navigate(`/zapisy/${slug}`)
  }

  return (
    <section className="bg-indigo-50">
    <div className="container m-auto max-w-2xl py-24">
      <div
        className="bg-white px-6 py-8 mb-4 shadow-md rounded-md border m-4 md:m-0"
      >
        <form onSubmit={submitForm}>
          <h2 className="text-3xl text-center font-semibold mb-6">Zapisz się do turnieju</h2>

          <div className="mb-4">
            <label htmlFor="name1" className="block text-gray-700 font-bold mb-2"
              >Nazwa teamu</label
            >
            <input
              type = "text"
              id="team"
              name="team"
              className="border rounded w-full py-2 px-3"
              required
              value = {team}
              onChange={(e)=>setTeam(e.target.value)}
             />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2"
              >Liczba zawodników w teamie</label>
            <select
              id="players_count"
              name="players_count"
              className="border rounded w-full py-2 px-3"
              required
              value = {players_count}
              onChange={(e)=> {setPlayersCount(e.target.value);setPlayers(newPlayerList(e.target.value))}}
              onClick={()=>setShowForm(true)}
            >
              <option value=""></option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
          <section>
          {showForm ? showInputs(players_count):<></>}
          </section>
          <div>
            <button
              className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Dodaj team
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
  )
}

export default RegistrationFormTeams