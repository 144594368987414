import styled from "styled-components";

const Tag = styled.p`
    padding: 0 0.5rem;
    align-items: center;
    display: flex;
    min-height: 25px;
    color: #ffffff;
`;

export default Tag;