import React from "react";
import styled from "styled-components";

const Info = styled.div`
  padding: 1.5rem;
  background-color: white;
  position: relative;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;

const Pinn = styled.div`
  font-size: 16px;
  margin-right: 0.5rem;
  display: inline;
`;

const Title = styled.h3`
  color: rgba(31, 41, 55, 1);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
`;

export default function InfoItem({ item, isPinned }) {
  return (
    <Info>
      <Title>
        {isPinned && <Pinn>📌</Pinn>}
        {item.title}
      </Title>
      <div>
        <span className="text-gray-500 text-xs space-x-4">
          <strong className="font-bold">Opublikowano</strong>:{" "}
          {new Date(item.date).toLocaleString()}
        </span>
        <p className="my-2 text-md text-gray-700 leading-relaxed">
          {item.description}
        </p>
        {item.link && (
          <a
            href={item.link}
            without
            rel="noreferrer"
            target="_blank"
            className="text-red-500 font-bold hover:underline hover:text-red-400"
          >
            Zobacz
          </a>
        )}
      </div>
    </Info>
  );
}
