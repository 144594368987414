import styled from "styled-components";

const Card = styled.section`
    background-color: white;
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    display: flex;
    max-width: 800px;
    margin: auto;
    margin-bottom: 2rem;
    padding: 2rem;
    gap: 2rem;
    flex-direction: row;
    color: black;
    
    @media only screen and (max-width: 40em) {
        flex-direction: column;
        padding: 1rem;
      }
`;

export default Card;