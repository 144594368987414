import React from "react";
import Container from "./atoms/Container";
import StyledIframe from "./atoms/StyledIframe";

export default function Rules() {
    return (
        <Container>
            <StyledIframe src="https://odra.bridgeabsurd.com/regulamin.pdf" />
        </Container>
        )
} 