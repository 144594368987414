import React, { useEffect } from "react";
import { RouterProvider, Route, createHashRouter, createRoutesFromElements } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import SinglePages from "./components/SinglePost";
import SingleOccasionalPost from "./components/SingleOccasionalPost";
import Tuesday from "./components/Tuesday";
import Occasional from "./components/Occasional";
import Infos from "./components/Infos";

import Rules from "./components/Rules";
import Registration from "./components/Registration";
import RegistrationPage from "./components/RegistrationPage";
import RegistrationForm from "./components/RegistrationForm";
import { MainLayout } from "./components/MainLayout";
import RegistrationFormTeams from "./components/RegistrationFormTeams";

import axios from 'axios'


function App() {
  useEffect(() => {
    document.title = "Turnieje Wtorkowe Wrocław";
  }, []);

  

  const addPlayer = async (newPlayer) => {
    try {
      await axios.post(`https://brydzwroclaw.com/v1/pairs/${newPlayer.tournament}`,newPlayer)
    }
    catch(err){
      console.log(err)
    }
    return
  }

  const addTeam = async (newTeam) => {
    console.log(newTeam)
    try {
      await axios.post(`https://brydzwroclaw.com/v1/teams/${newTeam.tournament}`,newTeam)
    }
    catch(err){
      console.log(err)
    }
    return
  }

  const router = createHashRouter(
    createRoutesFromElements(
      <Route path="/" element={<MainLayout />}>
        <Route index element ={<Home />}/>
        <Route element={<About />} path="/info" />
        <Route element={<SinglePages />} path="/wtorkowe/:slug" />
        <Route element={<Tuesday />} path="/wtorkowe" />
        <Route element={<SingleOccasionalPost />} path="/okazjonalne/:slug" />
        <Route element={<Occasional />} path="/okazjonalne" />
        <Route element={<Infos />} path="/ogloszenia" />
        <Route element={<Registration />} path="/zapisy" />
        <Route element={<RegistrationPage />} path="/zapisy/:slug"/>
        <Route element ={<RegistrationForm addPlayerSubmit={addPlayer}/>} path = "/zapisy/:slug/form" /> 
        <Route element ={<RegistrationFormTeams addTeamSubmit={addTeam}/>} path = "/zapisy/:slug/team-form" /> 
        <Route element={<Rules />} path="/regulamin" />
      </Route>
    )
  )

  return <RouterProvider router={router} />

  // return (
  //   <HashRouter >
  //     <NavBar />
  //     <Routes>
  //       <Route element={<Home />} path="/" />
  //       <Route element={<About />} path="/info" />
  //       <Route element={<SinglePages />} path="/wtorkowe/:slug" />
  //       <Route element={<Tuesday />} path="/wtorkowe" />
  //       <Route element={<SingleOccasionalPost />} path="/okazjonalne/:slug" />
  //       <Route element={<Occasional />} path="/okazjonalne" />
  //       <Route element={<Infos />} path="/ogloszenia" />
  //       <Route element={<Registration />} path="/zapisy" />
  //       <Route element={<RegistrationPage />} path="/zapisy/:slug" />
  //       <Route element ={<RegistrationForm addPlayerSubmit={addPlayer}/>} path = "/zapisy/:slug/form" /> 
  //       <Route element={<Rules />} path="/regulamin" />
  //     </Routes>
  //     <Footer />
  //   </HashRouter>
  // );
}

export default App;
