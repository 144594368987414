import React from "react";
import Container from "./atoms/Container";
import Card from "./atoms/Card";

export default function Home() {
    return (
        <Container>
                <Card>
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl text-green-500 mb-4 pb-4">Turnieje Wrocław 
                         </h1>
                        <div>
                            <p><b>Kiedy?</b>  W drugi poniedziałek miesiąca <br />
                            <b>O której?</b>  17:30<br />
                            <b>Ilość rozdań:</b>30<br />
                            <b>Gdzie?</b> <a href="https://azswratislavia.pl/kontakt/" without rel="noreferrer" target="_blank"> <i className="wo-text-decoration text-green-500">Klub AZS Wratislavia Na Grobli 30-32</i></a>, drugie piętro<br />
                            <br />
                            <i>Na stronie znajdują się też wyniki innych turniejów, rozgrywanych poza cyklem KMP</i>
                            </p> </div>
                    </div>
                </Card>
                <Card>
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl text-green-500 mb-4 pb-4">Wpisowe 
                         </h1>
                        <div>
                            <p><b>Ulgowe (juniorzy + seniorzy, w PZBS)</b>  -  20zł<br />
                            <b>Normalne</b>  -  25zł</p>
                        </div>
                    </div>
                </Card>
        </Container>
        )
} 