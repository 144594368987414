import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom'

const Info = styled.div`
  padding: 1.5rem;
  background-color: white;
  position: relative;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;


const Title = styled.h3`
  color: rgba(31, 41, 55, 1);
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
`;

const StyleTable = styled.table`
    max-width: 670px;
    margin: auto;

    border: 1px solid;
    padding: 15px;
    text-align: center;
    margin-top: 40px;
    width: 90%
`;

const StyleRowFirst = styled.tr`
    white-space: nowrap;
    border: 2px solid;
    border-color: black black grey black;
`

const StyleRowSecond = styled.tr`
  border: 2px solid;
  background-color: lightblue;
`


const currentDate = new Date()

const isActive = (date, current_date) =>{
    const datetime = new Date(date) 
        return datetime.getTime() > current_date.getTime()
} 

export default function TournamentItem({ item, slug }) {
  return (
    <Info>
      <Title>
        {item.title}
      </Title>
      <div className="bg-white p-6 rounded-lg shadow-md mt-6">
            <h3 className="text-indigo-800 text-lg font-bold mb-6">
                Opis turnieju
            </h3>

            <p className="mb-4">
                {item.description}
            </p>

            <h3 className="text-indigo-800 text-lg font-bold mb-2">Termin</h3>

            <p className="mb-4">{item.date}</p>
            <h3 className="text-indigo-800 text-lg font-bold mb-2">Zapisy otwarte do</h3>
            <p className="mb-4">{item.date_registration}</p>


            <h3 className="text-indigo-800 text-lg font-bold mb-2">Wpisowe</h3>
            <StyleTable>
            <thead>
              <StyleRowSecond key='feeheader'>
                  <th>Nierzeszeni</th><th>Zrzeszeni, bez ulg</th><th>Ulgowe</th>
              </StyleRowSecond>
              </thead>
              <tbody> 
                <StyleRowFirst key = 'fee'>
                <td>{item.Fee}</td>
                <td>{item.regFee}</td>
                <td>{item.redFee}</td>
              </StyleRowFirst></tbody>
            </StyleTable>
            </div>
            {isActive(item.date_registration, currentDate) ? <Link
            to={item.typeName === "couples" ? `/zapisy/${slug}/form`:`/zapisy/${slug}/team-form`}
            className="bg-indigo-500 hover:bg-indigo-600 text-white text-center font-bold py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline mt-4 block"
            >Zapisz się</Link> : 
            <></>}               
            
    </Info>
  );
}