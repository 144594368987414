import styled from "styled-components";

const Container = styled.main`
    padding: 2.5rem 0.5rem;
    min-height: 90vh;
    
    @media only screen and (max-width: 40em) {
      margin-top: 4rem;
    }
`;

export default Container;