import styled from "styled-components";

const StyledWebcoFooter = styled.div`
  background-color: #151515;
  width: 100%;
  height: 80px;
  color: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 40em) {
  }
`;

const StyledLink = styled.a`
  color: #10b981;
  text-transform: none;
`;

const LinkWrapper = styled.span`
  text-align: center;
`;

const WebcoFooter = () => {
  return (
    <StyledWebcoFooter>
      <LinkWrapper>
        Copyright © 2021-2023{" "}
        <StyledLink href="https://webcodesign.pl/" target="_blank">
          webco.design
        </StyledLink>{" "}
        All rights reserved.{" "}
      </LinkWrapper>
    </StyledWebcoFooter>
  );
};

export default WebcoFooter;
