import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import Container from "./atoms/Container";
import Item from "./atoms/Item";
import styled from "styled-components";


const IsActiveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 10px;

  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
`;

const ActiveButton = styled.button`
  color: white;

  background-color: ${(props) =>
    props.isSelected ? "rgb(171, 0, 51);" : "black"};
  padding: 0.3em 0.5em;
  margin: auto;

  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;

const dateFilter = (date, current_date, isPast) =>{
    const datetime = new Date(date)
    if (isPast) 
        return datetime.getTime() < current_date.getTime()
    else 
        return datetime.getTime()> current_date.getTime()
} 


export default function Registration() {
    const [postData, setPost] = useState(null);
    const currentDate = new Date();
    const [past, setPast] = useState(false);
  
    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "registration"]{
              title, 
              date,
              date_registration,
              slug,
          }`
        )
        .then((data) => setPost(data))
        .catch(console.error);
    }, []);
  
    useEffect(() => {});
  
  

    // const Registration = () => {
    return (
        <Container>
            <section className="container mx-auto">
            <IsActiveContainer>
                <ActiveButton onClick={()=>setPast(false)}>Aktywne zapisy</ActiveButton>
                <ActiveButton onClick={()=>setPast(true)}>Zamknięte zapisy</ActiveButton>
            </IsActiveContainer>
            <div className="grid gap-2">
                {postData &&
                postData
                .filter((post) => dateFilter(post.date_registration, currentDate, past ))
                .map((post) => (
                    <article>
                    <Link
                        to={"/zapisy/" + post.slug.current}
                        key={post.slug.current}
                    >
                        <Item
                        name={post.title}
                        typeName={post.type}
                        date={post.date}
                        date_registration = {post.date_registration}
                        />
                    </Link>
                    </article>
                ))}
            </div>
            </section>
        </Container>
        );
}

// export default Registration

