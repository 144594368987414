import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
 


const RegistrationForm = ({addPlayerSubmit}) => {
  const [Name1, setName1] = useState("")
  const [Name2, setName2] = useState("")
  const [WK1, setWK1] = useState("")
  const [WK2, setWK2] = useState("")
  const [PID1, setPID1] = useState("")
  const [PID2, setPID2] = useState("")
  

  const navigate = useNavigate()
  const {slug} = useParams()

  const submitForm = (e) => {
    
    e.preventDefault()

    const newCouple = {
      player1:{
        name: Name1,
        WK: WK1,
        PID: PID1,
      },
      player2:{
        name: Name2,
        WK: WK2,
        PID: PID2,
      },
      tournament: slug
      }
      addPlayerSubmit(newCouple, slug)

      return navigate(`/zapisy/${slug}`)
  }

  return (
    <section className="bg-indigo-50">
    <div className="container m-auto max-w-2xl py-24">
      <div
        className="bg-white px-6 py-8 mb-4 shadow-md rounded-md border m-4 md:m-0"
      >
        <form onSubmit={submitForm}>
          <h2 className="text-3xl text-center font-semibold mb-6">Zapisz się do turnieju</h2>

          <div className="mb-4">
            <label htmlFor="name1" className="block text-gray-700 font-bold mb-2"
              >Imię i nazwisko pierwszego zawodnika</label
            >
            <input
              type = "text"
              id="name1"
              name="name1"
              className="border rounded w-full py-2 px-3"
              required
              value = {Name1}
              onChange={(e)=>setName1(e.target.value)}
             />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2"
              >WK pierwszego zawodnika</label>
            <select
              type="text"
              id="surname1"
              name="surname1"
              className="border rounded w-full py-2 px-3 mb-2"
              required
              value = {WK1}
              onChange={(e)=>setWK1(e.target.value)}>
              <option value=""></option>
              <option value="0.0">0.0</option>
              <option value="0.5">0.5</option>
              <option value="1.0">1.0</option>
              <option value="1.5">1.5</option>
              <option value="2.0">2.0</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="7">7</option>
              <option value="9">9</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="15">15</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="21">21</option>
              <option value="24">24</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2"
              >PID pierwszego zawodnika</label>
            <input
              type="number"
              id="PID1"
              name="PID1"
              className="border rounded w-full py-2 px-3 mb-2"
              required
              min = "0"
              max = "30000"
              value = {PID1}
              onChange={(e)=>setPID1(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="name2" className="block text-gray-700 font-bold mb-2"
              >Imię i nazwisko drugiego zawodnika</label
            >
            <input
              type = "text"
              id="name2"
              name="name2"
              className="border rounded w-full py-2 px-3"
              required
              value = {Name2}
              onChange={(e)=>setName2(e.target.value)}
             />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2"
              >WK drugiego zawodnika</label>
            <select
              type="text"
              id="WK2"
              name="WK2"
              className="border rounded w-full py-2 px-3 mb-2"
              required
              value = {WK2}
              onChange={(e)=>setWK2(e.target.value)}>
              <option value=""></option>
              <option value="0.0">0.0</option>
              <option value="0.5">0.5</option>
              <option value="1.0">1.0</option>
              <option value="1.5">1.5</option>
              <option value="2.0">2.0</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="7">7</option>
              <option value="9">9</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="15">15</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="21">21</option>
              <option value="24">24</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2"
              >PID drugiego zawodnika</label>
            <input
              type="number"
              id="PID2"
              name="PID2"
              className="border rounded w-full py-2 px-3 mb-2"
              required
              min = "0"
              max = "30000"
              value = {PID2}
              onChange={(e)=>setPID2(e.target.value)}
            />
          </div>
          <div>
            <button
              className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Dodaj parę
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
  )
}

export default RegistrationForm